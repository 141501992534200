<template>
    <vue-plotly style="width:100%;" :data="data" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'SimpleBarChart',
  components: { VuePlotly },
  props: {
    x: Array,
    y: Array,

    order: {
      type: String,
      default: null
    },
    ordertarget: {
      type: String,
      default: null
    },
    transformation: {
      type: String,
      default: null
    },
    labelcolor: {
      type: String,
      default: null
    },
    marginleft: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 400
    },
    color: {
      type: Array,
      default () {
        return ['rgba(140, 140, 140,1.0)',
                'rgba(140, 140, 140,0.6)',
                'rgba(140, 140, 140,0.2)',
                'rgba(144, 144, 144,0.6)',
                'rgba(137, 137, 137,0.2)',
                'rgba(137, 137, 137,0.6)',
                'rgba(137, 137, 137,1.0)'
              ]
            }
    },
    tickangle: {
      type: Number,
      default: 0
    },
    xtitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      layout: {
        xaxis: {
          tickangle: this.tickangle,
          title: {
            text: this.xtitle,
            font: {
              family: 'Gotham',
              size: 14,
            },
          },
        },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        height: this.height,
        margin: {
          l: this.marginleft,
          r: 50,
          b: 60,
          t: 30,
          pad: 4},
      },
      options: {
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    data () {
      return [
        {
          type: 'bar',
          transforms: [{
            type: this.transformation,
            target: this.ordertarget,
            order: this.order
          }],
          x: this.x,
          textfont: {
            color: this.labelcolor
          },
          y: this.y,
          text:this.x,
          margin:{
            marginleft: this.marginleft
          },
          textposition: 'auto',
          hoverinfo: 'none',
          orientation: 'h',
          marker: {
            color: this.color
          }
        },
      ]
    }
  }
}

</script>
