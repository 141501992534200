<template>
    <vue-plotly style="width:80%;" :data="data" :layout="layout"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'HorizontalBarChart',
  components: { VuePlotly },
  props: {
    x: Array,
    xtitle: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 400
    },
    y: Array,
    title: String,
    ticklabels: {
      type: Boolean,
      default: true
    },
    tickangle: {
      type: Number,
      default: 0,
    },
    fontsize: {
      type: Number,
      default: 14
    },
    marginleft: {
      type: Number,
      default: 120
    },
    range_value: Number
  },
  data () {
    return {
      colorbar: [],
      layout: {
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        xaxis: {
          title: {
            text: this.xtitle,
            font: {
              family: 'Gotham',
              size: 14
            },
          },
          range: [0,this.range_value],
          tickformat: ',.0%'
        },
        margin: {
          l: this.marginleft,
          r: 30,
          b: 50,
          t: 30,
          pad: 4},
        title: {
          text:this.title,
          font: {
            size:15,
            family: 'Calibri',
          },
        xref: 'paper',
        x: 0.00,
        },
        height: this.height,
        yaxis: {
          showticklabels: this.ticklabels,
          font: {
            size:this.fontsize,
            family: 'Gotham',
          },
          tickfont: {
              size:9,
              family: 'Gotham',
            },
          // fontsize: this.fontsize
        // anchor: 'free',
        // side: 'right',
        // position:-10
          },
      },
      options: {
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  watch: {
    '$store.state.selectedRegion': function () {
      this.getColors()
    },
    x () {
      this.getColors()
    }
  },
  computed: {
    data () {
      return [
      { type: 'bar',
        x: this.x,
        y:this.y,
        orientation: 'h',
        text:this.x.map(d=>(d*100).toFixed(1)),
        textposition: 'auto',
        // hoverinfo: 'none',
        marker: {
          color: this.colorbar
          }
        }
      ]
    }
  },
  mounted () {
    this.getColors()
  },
  methods: {
    getColors () {
      var colorArray = [];
      for (var i = 0; i<this.y.length; i++)
        if (this.y[i] === this.$store.state.selectedRegion) {
          colorArray.push('rgba(20,66,115,1.00))')
        }
        else if (this.y[i] === 'Landelijk') {
            colorArray.push(`rgba(51,127,100,1.00))`)
          }
        else {
          colorArray.push('rgba(180,180,180,1.00)')
        }
      this.colorbar=colorArray
    }
  }
}

</script>

<style>


</style>
