<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
        <div class="col-sm-12 down">
          <h1 class="intro-section-title">Identificatie van mensen met dementie</h1>
          <div class="row">
            <p class="intro-section-text">
              Op deze pagina wordt een overzicht gegeven van de identificatie van mensen met dementie. Het doel hiervan is om een goede inschatting te geven van het aantal mensen met dementie, zowel landelijk als per regio. Ook is onderzocht hoe demografische verschillen tussen regio’s gerelateerd zijn aan het verwachte aantal mensen met dementie. In regio's waar het geschatte aantal mensen met dementie hoger is dan werkelijk is geïdentificeerd kan mogelijk extra aandacht worden geschonken aan het identificeren van mensen met dementie. Tot slot is een prognose gedaan voor de toekomst op basis van demografische ontwikkelingen. Voor de prognoses wordt gebruik gemaakt van data uit de Gezondheidsmonitor Volwassenen en Ouderen 2016 en 2020, GGD’en, CBS en RIVM.
            </p>
          </div>
        </div>
      </div>

      <div class="row" v-if="selectedLevel === 'zorgkantoor'">
        <div class="col-sm-12 down">
          <div class="middle-title-section">
            <h1 class="intro-section-subtitle">Werkelijk aantal mensen met dementie in {{selectedYear}}</h1>
          </div>
        </div>
      </div>

      <div class="row" v-if="selectedLevel === 'zorgkantoor'">
        <div class="col-sm-12">
          <card :cardId="'identificatie-1'" :hovertext="'De identificatie vindt plaats op basis van o.a. Wlz gegevens en medicijngebruik. Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'" 
            :x="geidentificeerd_zorgkantoor" :y="geidentificeerd_voorspelmodel" :z="geidentificeerd_totaal "
            :xtitle="'Regio naam'"  :ytitle="`Werkelijk geïdentificeerd 65+ in ${selectedYear}`" 
            :ztitle="`Werkelijk geïdentificeerd in ${selectedYear}`" 
            :tableHeight="1000">
              <template slot="card-header" >Mensen met dementie in Nederland in {{selectedYear}} </template>
              <div slot="card-body">
                In deze grafiek is een overzicht te zien van geïdentificeerde mensen met dementie in Nederland in {{selectedYear}}.
                  <simple-bar-chart v-if="load.graphThree" :xtitle="'Aantal mensen met dementie'" :x="[geidentificeerd_aggr, geidentificeerd_totaal_aggr]" :y="[`Werkelijk geïdentificeerd 65+ in ${selectedYear}`, `Werkelijk geïdentificeerd in ${selectedYear}`]" :marginleft="250"/>
              </div>
          </card>
        </div>
      </div>

      <div class="row" v-if="selectedLevel === 'dementienetwerk'">
        <div class="col-sm-12">
          <card-small :cardId="'identificatie-1-1'" v-if="selectedLevel==='dementienetwerk'">
            <template slot="card-header">Mensen met dementie in Nederland in {{selectedYear}} - niet beschikbaar voor Dementienetwerken</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel === 'zorgkantoor'">
        <div class="col-sm-12">
          <card-large :cardId="'identificatie-2'" :hovertext="''" 
            :x="netwerken" :y="verschil_table.map(value => `${value}%`)"
            :xtitle="'Regio naam'"  :ytitle="'Werkelijk'" 
            :tableHeight="1000">
          <template slot="card-header">Mensen met dementie per regio</template>
            <div slot="card-body">
                Het aantal mensen boven de 65 met dementie in de regio als percentage van alle mensen boven de 65 (met en zonder dementie) in de regio.
                <horizontal-bar-chart-fixed-range v-if="load.graphTwo" :x="verschil" :y="netwerken" :height="870" :marginleft='200' :title="'Alle regio`s'" :range_value="0.10"/>
            </div>
        </card-large>
      </div>
      </div>



    <!-- <div class="row" v-if="selectedLevel === 'zorgkantoor'"> -->
      <!-- <div class="col-sm-6">
        <div class="row down">
          <card :cardId="'identificatie-1'" :hovertext="'De identificatie vindt plaats op basis van o.a. Wlz gegevens en medicijngebruik. Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'">
              <template slot="card-header" >Mensen met dementie in Nederland in {{selectedYear}} </template>
              <div slot="card-body">
                In deze grafiek is een overzicht te zien van geïdentificeerde mensen met dementie in Nederland in {{selectedYear}}.
                  <simple-bar-chart v-if="load.graphThree" :xtitle="'Aantal mensen met dementie'" :x="[geidentificeerd_aggr, geidentificeerd_totaal_aggr]" :y="[`Werkelijk geïdentificeerd 65+ in ${selectedYear}`, `Werkelijk geïdentificeerd in ${selectedYear}`]" :marginleft="250"/>
              </div>
          </card>
          <card :cardId="'identificatie-1-1'" v-if="selectedLevel==='dementienetwerk'">
            <template slot="card-header">Mensen met dementie in Nederland in {{selectedYear}} - niet beschikbaar voor Dementienetwerken</template>
          </card>
        </div>
      </div> -->
    
      <!-- <div class="col-sm-6">
        <div class="row down">
        <card-large :cardId="'identificatie-2'" :hovertext="''">
          <template slot="card-header">Mensen met dementie per regio</template>
            <div slot="card-body">
                Het aantal mensen boven de 65 met dementie in de regio als percentage van alle mensen boven de 65 (met en zonder dementie) in de regio.
                <horizontal-bar-chart-fixed-range v-if="load.graphTwo" :x="verschil" :y="netwerken" :height="870" :marginleft='200' :title="'Alle regio`s'" :range_value="0.10"/>
            </div>
        </card-large>
      </div>
      </div> -->
    <!-- </div> -->

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12">

        <card-small :cardId="'identificatie-3'" :hovertext="'Deze grafiek bevat data over alle geïncludeerde personen met dementie en geeft aan op basis van welke indicator voor het eerst dementie werd gediagnosticeerd. Dit is op basis van jaarniveau-data, dus of iemand in een bepaald jaar is gediagnosticeerd o.b.v. Wlz-zorg/indicatie, ziekenhuiszorg, medicatie of een andere bron. Wanneer dementie voor het eerst is gediagnosticeerd op basis van meerdere zorgvormen dan volgt de hiërarchie: medicatie>ziekenhuis>wlz>overig. Iemand die tegelijkertijd scoort op alle identificatiemethoden zal dus worden aangeduid als iemand die voor het eerst op medicatiegegevens werd geïdentificeerd.'">
            <template slot="card-header">Eerste identificatie per {{selectedLevel}} in {{selectedYear}} </template>
            <div slot="card-body">
            De grafiek over eerste diagnose van mensen met dementie wordt op korte termijn toegevoegd
          </div>
          </card-small>

        <card-small :cardId="'identificatie-3-1'" v-if="selectedLevel==='dementienetwerk'">
          <template slot="card-header">Eerste diagnose per {{selectedLevel}} - niet beschikbaar voor Dementienetwerken</template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
        <div class="col-sm-12 down">
          <div class="middle-title-section">
            <h1 class="intro-section-subtitle">Verwacht aantal 65+ mensen met dementie in 2020</h1>
          </div>
        </div>
      </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12">
        <card-large :cardId="'identificatie-4'" :hovertext="'Het verwachte aantal mensen met dementie is tot stand gekomen met behulp van een regressiemodel op alle geïdentificeerde 65+’ers met dementie in Nederland. Hierin zijn kenmerken verwerkt zoals leeftijd, geslacht, woonsituatie, inkomen, gezondheidskenmerken en zorggebruik. Deze kenmerken kunnen positief of negatief samenhangen met de kans om geïdentificeerd te worden als een persoon met dementie. In iedere zorgkantoorregio is op basis van bovenstaande factoren voor alle inwoners geschat hoe groot de kans is om geïdentificeerd te worden met dementie, deze kansen zijn gebruikt om een verwacht aantal mensen met dementie te voorspellen. De verwachting geeft zodoende weer hoeveel personen met dementie worden gewacht in een regio op basis van persoonskenmerken, bijvoorbeeld: in een regio met veel ouderen is het verwachte aantal hoger dan in een regio met een relatief jonge bevolking. Verschillen tussen identificatie en verwachting kunnen voortkomen uit allerlei verschillen tussen regio’s: Het zou bijvoorbeeld kunnen dat personen in deze regio langer ongeïdentificeerd blijven dan soortgelijke personen in andere regio’s doordat zij later gebruik maken van bepaalde vormen van zorg (bijvoorbeeld door de beschikbaarheid van mantelzorgers) of doordat het zorgbeleid in deze regio anders is ingericht. Het verschil tussen het geobserveerde aantal mensen met dementie en het verwachte aantal is zodoende niet per definitie goed of fout maar biedt aanknopingspunten voor vervolgonderzoek.'" 
          :x="location_names" :y="factors_table.map(value => `${value}%`)"
            :xtitle="'Regio naam'"  :ytitle="'Werkelijk'" 
            :tableHeight="selectedLevel === 'dementienetwerk' ? 7000 : 9000">
          <template slot="card-header">Aantal geïdentificeerde mensen t.o.v. verwachting per regio</template>
            <div slot="card-body">
                  Op deze kaart van Nederland vindt u de onder- en overidentificatie per {{selectedLevel}}.
                  Met kenmerken van de bevolking, zoals leeftijd, geslacht, zorggebruik en gezondheid, berekenen wij een verwacht aantal mensen met dementie in de regio. Een waarde van 120% betekent dat in die regio 20% meer mensen met dementie zijn geïdentificeerd dan dat we op basis van het voorspelmodel hadden verwacht. 
                  <br><br>
                  Een verschil kan meerdere oorzaken hebben: de prevalentie van dementie wijkt in deze regio af van het landelijk gemiddelde, mensen met dementie gebruiken andere zorg dan in andere delen van Nederland of de wijze van diagnose stellen wijkt af. Dit laatste kan regio’s aanknopingspunten geven om de mensen met dementie nóg beter van een passende diagnose te voorzien.
                <choropleth  v-if="load.graphOne" :colorscale="colorscale" :zmax='120' :zmin="70" :zoom="6.3" :height="700" :z="factors" :locations="location_factors" :text="location_names" :colorbar="{ ticksuffix: '%' }" :hovertemplate= "'<extra><br>%%{z:.1f}<br>%{text}<br></extra>'"/>
              </div>
            </card-large>
        </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'dementienetwerk'">
      <div class="col-sm-12 down">
        <div class="middle-title-section">
          <h1 class="intro-section-subtitle">Prognose aantal 65+ mensen met dementie in 2050</h1>
        </div>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
        <div class="col-sm-12">
              <card :cardId="'identificatie-5'" v-if="selectedLevel!=='dementienetwerk'" :hovertext="'De prognose is gebaseerd op de voorspelde demografische ontwikkelingen en een simpel model om het aantal mensen met dementie op basis van geslacht en leeftijd te bepalen. De verwachting is gebaseerd op het toevoegen van een vergelijkbare groep mensen op basis van geslacht, leeftijd, SES, medicijngebruik, etc van de inwoners van Dementienetwerk. De prognose is gebaseerd op de bevolkingsgroei en een simpel model om het aantal mensen met dementie op basis van geslacht en leeftijd te bepalen. CBS rapporteert geen gegevens over gemeentes kleiner dan 50.000 inwoners, dus voor sommige Dementienetwerken is deze informatie niet beschikbaar.'" 
                :x="geidentificeerd_zorgkantoor" :y="geidentificeerd_totaal" 
                :xtitle="'Regio naam'" :ytitle="'Aantal'"
                :tableHeight="900">
                <template slot="card-header" >Mensen met dementie in Nederland in {{selectedYear}} en prognoses tot 2050</template>geidentificeerd_zorgkantoor geidentificeerd_totaal
                <div slot="card-body">
                In deze grafiek is een overzicht te zien van aantal geïdentificeerde mensen met dementie in Nederland in 2020 op basis van zorg, medicijngebruik en diagnoses. Daarnaast worden prognoses in het voorspelde aantal personen met dementie voor 2025, 2030, 2040, en 2050 op basis van voorspelde demografische ontwikkelingen getoond.
                    <simple-bar-chart v-if="load.graphThree" :marginRight="0" :xtitle="'Aantal mensen met dementie'" :x="[prognose_2050_aggr, prognose_2040_aggr, prognose_2030_aggr, prognose_2025_aggr, geidentificeerd_aggr, geidentificeerd_totaal_aggr]" :y="['Prognose 65+ in 2050', 'Prognose 65+ in 2040', 'Prognose 65+ in 2030', 'Prognose 65+ in 2025', `Werkelijk 65+ in ${selectedYear}`, `Werkelijk in ${selectedYear}`]" />
                </div>
              </card>
              <card-small :cardId="'identificatie-5-1'" v-if="selectedLevel==='dementienetwerk'">
                <template slot="card-header">Mensen met dementie in Nederland in {{selectedYear}} en prognoses tot 2050 - niet beschikbaar voor Dementienetwerken</template>
              </card-small>
          </div>
      </div>
      <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12">
        <card :cardId="'identificatie-6'" v-if="selectedLevel!=='dementienetwerk'" :hovertext="'De prognose is gebaseerd op de voorspelde demografische ontwikkelingen en een simpel model om het aantal mensen met dementie op basis van geslacht en leeftijd te bepalen.'" 
          :x="zorgkantoorregionaam_voorspelmodel_table" :y="geidentificeerd_voorspelmodel_table"  :z="prognose_2050_voorspelmodel_table" 
          :xtitle="'Regio naam'" :ytitle="'Aantal'" :ztitle="'Aantal'"
          :tableHeight="900">
          <template slot="card-header">Prognose aantal mensen met dementie in 2050 per {{selectedLevel}}</template>
            <div slot="card-body">
               <grouped-bar-chart
              :height="475"
              :width="1000"
              :ticktext=zorgkantoorregionaam
              :marginBottom="200"
              :tickangle="45"
              :tickvals="[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]"
              :xtitle="`Zorgkantoor`"
              :ytitle="'Aantal mensen met dementie'" :tickformat="''" v-if="load.graphFour"
              :names="[`Werkelijk geidentificeerd 65+ in ${selectedYear}`,'Prognose 2050 (65+)']"
              :x="[zorgkantoorregionaam,zorgkantoorregionaam]"
              :y="[geidentificeerd,prognose_2050]"/>
            </div>
        </card>
        <card-small :cardId="'identificatie-6-1'" v-if="selectedLevel==='dementienetwerk'">
          <template slot="card-header">Prognose aantal mensen met dementie in 2050 per {{selectedLevel}} - niet beschikbaar voor Dementienetwerken</template>
        </card-small>
      </div>
    </div>
    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12">
        <card :cardId="'identificatie-7'" v-if="selectedLevel!=='dementienetwerk'" :hovertext="''" 
          :x="zorgkantoorregionaam_perc" :y="perc_prognose_2050_table"
          :xtitle="'Regio naam'" :ytitle="'Aantal'"
          :tableHeight="900">
          <template slot="card-header">Groei percentage mensen met dementie in 2050 t.o.v. het werkelijke aantal in {{selectedYear}}</template>
            <div slot="card-body"> Deze grafiek laat zien hoe hard het aantal mensen met dementie groeit. De noemer is aantal 65+ mensen met dementie in 2020 en de teller is het verwachte aantal 65+ mensen met dementie in 2050. 
               <grouped-bar-chart
              :height="475"
              :width="1000"
              :showlegend="false"
              :ticktext=zorgkantoorregionaam
              :marginBottom="200"
              :tickangle="45"
              :tickvals="[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32]"
              :xtitle="`Zorgkantoor`"
              :ytitle="'Percentage groei Prognose 2050'" :tickformat="''" v-if="load.graphFive"
              :names="['Percentage groei Prognose 2050 (65+)']"
              :x="[zorgkantoorregionaam_perc]"
              :y="[perc_prognose_2050]"/>
            </div>
        </card>
        <card-small :cardId="'identificatie-7-1'" v-if="selectedLevel==='dementienetwerk'">
          <template slot="card-header">Groei percentage mensen met dementie in 2050 t.o.v. het werkelijke aantal - niet beschikbaar voor Dementienetwerken</template>
        </card-small>
      </div>
    </div>

    <div class="row" style="margin-top: -.5rem">
      <div class="col-sm-12">
        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-6 down">
              <card :cardId="'factsheet8-landelijk-1'" :hovertext="''" 
              :x="zorgkantoor_totaal_jaar" :y="verschil_table.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Percentage'" 
              :tableHeight="300">
                <template slot="card-header">Mensen met dementie per regio</template>
                <div slot="card-body" style="height: 500px;">
                  Onderstaande grafiek toont het landelijk gemiddelde percentage van het aantal 65-plussers met dementie in de regio als percentage van alle 65-plussers (met en zonder dementie) in de regio. 
                  <stacked-bar-chart-colors v-if="load.graphSix" :isLandelijk="false"
                    :ytitle="'% mensen met dementie'"
                    :xtitle="'Jaar'"
                    :x="[zorgkantoor_totaal_jaar]"
                    :y="[verschil]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        

            <div class="col-sm-6 down">
              <card :cardId="'factsheet8-landelijk-2'" :hovertext="''" 
                :x="zorgkantoorregionaam_voorspelmodel" :y="perc_prognose_2050_table.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Groei percentage mensen met dementie in 2050 t.o.v. het werkelijke aantal</template>
                <div slot="card-body" style="height: 500px;">
                  Deze grafiek toont het landelijk gemiddelde percentage van hoe snel het aantal mensen met dementie groeit. De noemer is het aantal 65+-mensen met dementie in 2020 en de teller is het verwachte aantal 65-plussers met dementie in 2050.
                  <stacked-bar-chart-colors v-if="load.graphSix" :isLandelijk='false'
                    :ytitle="'% groei prognose 2050'"
                    :x="[zorgkantoorregionaam_voorspelmodel]"
                    :y="[perc_prognose_2050]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
          </div>
      </div>
    </div>

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>

</template>

<script>

import Card from '../components/Card'
import CardLarge from '../components/CardLarge'
import CardSmall from '../components/CardSmall'
import SimpleBarChart from '../components/SimpleBarChart'
//import HorizontalBarChart from '../components/HorizontalBarChart'
import HorizontalBarChartFixedRange from '../components/HorizontalBarChartFixedRange'
import GroupedBarChart from '../components/GroupedBarChart'
import StackedBarChartColors from '../components/StackedBarChartColors'
// import WaterfallH from '../components/Waterfall_horizontal'
import Choropleth from '../components/Choropleth'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
    metaInfo: {
      title: 'Identificatie',
    },
  name: 'Identificatie',
  components: { CardSmall, Card, CardLarge, SimpleBarChart, HorizontalBarChartFixedRange, GroupedBarChart, Choropleth, StackedBarChartColors, Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      geidentificeerd_zorgkantoor: [],
      geidentificeerd_voorspelmodel: [],
      geidentificeerd_totaal:[],
      factors: [],
      location_names: [],
      location_factors: [],
      netwerkcodes: [],
      piechartx: [],
      //0,150,50,50
      piecharty: [],
      variabele: [],
      probability: [],
      // '0-25% zekerheid', '25-50% zekerheid', '50-75% zekerheid', '75-100% zekerheid'
      netwerken: [],
      verschil: [],
      verschil_table : [],
      zorgkantoor_totaal_jaar: [],
      netwerken_top5: [],
      verschil_top5: [],
      netwerken_bot5: [],
      verschil_bot5: [],
      locations: [],
      locations1: [],
      z: [],
      text: [],
      text1: [],
      geidentificeerd_totaal_aggr: 0,
      geidentificeerd_aggr: 0,
      verwacht_aggr: 0,
      prognose_2025_aggr: 0,
      prognose_2030_aggr: 0,
      prognose_2040_aggr: 0,
      prognose_2050_aggr: 0,
      geidentificeerd: 0,
      prognose_2050: 0,
      perc_prognose_2050: 0,
      ui_zorgkantoor: [],
      wlz_perc: [],
      ziekenhuis_perc: [],
      doodsoorzaak_perc: [],
      medicatie_perc: [],
      huisarts_perc: [],
      overig_perc: [],
      zorgkantoorregionaam: [],
      zorgkantoorregionaam_perc: [],
      zorgkantoorregionaam_voorspelmodel:[],
      colorbar: [],
      factors_table: [],
      geidentificeerd_voorspelmodel_table :[],
      zorgkantoorregionaam_voorspelmodel_table :[],
      prognose_2050_voorspelmodel_table :[],
      perc_prognose_2050_table :[],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFive: false,
        graphMap  : false,
        graphSix: false
      },
      colorscale: [
        ['0.0', 'rgb(205, 230, 246)'],
        ['0.25', 'rgb(115, 182, 224)'],
        ['0.50', 'rgb(37, 116, 169)'],
        ['0.75', 'rgb(16, 63, 105)'],
        ['1.0', 'rgb(20, 66, 115)']
      ]
    }
  }, 
  watch: {
    '$store.state.selectedRegion': function (region) {
      this.loadAggregatedRegionData(region)
    },
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
      this.loadAggregatedRegionData(this.$store.state.selectedRegion)
      this.loadMapData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
      this.loadAggregatedRegionData(this.$store.state.selectedRegion)
      this.loadMapData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAggregatedRegionData () {
      if (this.$store.state.selectedLevel === 'zorgkantoor') {
        this.$http.get(`api/zorgkantoor_voorspelmodel/?jaar=2020`).then(
        (response) => {
          this.verwacht_aggr = response.data.map(d => ((d.voorspeld)/100)*100).reduce((a, b) => a + b, 0)
          this.prognose_2025_aggr = response.data.map(d => ((d.prognose_2025)/100)*100).reduce((a, b) => a + b, 0)
          this.prognose_2030_aggr = response.data.map(d => ((d.prognose_2030)/100)*100).reduce((a, b) => a + b, 0)
          this.prognose_2040_aggr = response.data.map(d => ((d.prognose_2040)/100)*100).reduce((a, b) => a + b, 0)
          this.prognose_2050_aggr = response.data.map(d => ((d.prognose_2050)/100)*100).reduce((a, b) => a + b, 0)
          }
        ),
        this.$http.get(`api/zorgkantoor_voorspelmodel/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            this.geidentificeerd_aggr = response.data.map(d => d.voorspeld).reduce((a, b) => a + b, 0)

            this.geidentificeerd_zorgkantoor = response.data.map(d => d.zorgkantoorregionaam)
            this.geidentificeerd_voorspelmodel = response.data.map(d => d.voorspeld)
          }
        )
        this.$http.get(`api/zorgkantoor_totaal/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.geidentificeerd_totaal_aggr = response.data.map(d => d.aantal).reduce((a, b) => a + b, 0)

          this.geidentificeerd_zorgkantoor = response.data.map(d => d.zorgkantoorregionaam)
          this.geidentificeerd_totaal = response.data.map(d => d.aantal)
          this.load.graphThree=true
          }
        ),
        this.$http.get(`api/zorgkantoor_voorspelmodel/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
          response.data.sort((a,b) => b.prognose_2050-a.prognose_2050)
          this.geidentificeerd = response.data.map(d => d.voorspeld)

          this.geidentificeerd_voorspelmodel_table = response.data.map(d => d.voorspeld)
          this.zorgkantoorregionaam_voorspelmodel_table = response.data.map(d => d.zorgkantoorregionaam)
          this.prognose_2050_voorspelmodel_table = response.data.map(d => d.prognose_2050)


          }
        )
        this.$http.get(`api/zorgkantoor_voorspelmodel/?jaar=2020`).then(
        (response) => {
          response.data.sort((a,b) => b.prognose_2050-a.prognose_2050)
          this.zorgkantoorregionaam = response.data.map(d => d.zorgkantoorregionaam)
          this.prognose_2050 = response.data.map(d => ((d.prognose_2050)/100)*100)
          this.load.graphFour=true
          }
        )
        this.$http.get(`api/zorgkantoor_voorspelmodel/get_data/?jaar=2020`).then(
        (response) => {
          response.data.sort((a,b) => b.prognose_2050-a.prognose_2050)
          this.zorgkantoorregionaam_perc = response.data.map(d => d.zorgkantoorregionaam)
          this.perc_prognose_2050 = response.data.map(d => ((d.perc_prognose_2050)*100))
          this.perc_prognose_2050_table = response.data.map(d => ((d.perc_prognose_2050)*100).toFixed(2))
          this.load.graphFive=true
          }
        )
       this.$http.get(`api/uitsplitsingidentificatie_zk/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => b.wlz_perc-a.wlz_perc)
          this.ui_zorgkantoor = response.data.map(d => d.zorgkantoorregionaam)
          this.wlz_perc = response.data.map(d => d.wlz_perc*100)
          this.ziekenhuis_perc = response.data.map(d => d.ziekenhuis_perc*100)
          this.doodsoorzaak_perc = response.data.map(d => d.doodsoorzaak_perc*100)
          this.medicatie_perc = response.data.map(d => d.medicatie_perc*100)
          this.huisarts_perc = response.data.map(d => d.huisarts_perc*100)
          this.overig_perc = response.data.map(d => d.overig_perc*100)
          this.load.graphFive=true
          }
        )
    }
    else if(this.$store.state.selectedLevel === 'landelijk'){
      this.$http.get(`api/zorgkantoor_voorspelmodel/get_data/?jaar=2020&landelijk=True`).then(
        (response) => {
          response.data.sort((a,b) => b.prognose_2050-a.prognose_2050)
          this.zorgkantoorregionaam_voorspelmodel = response.data.map(d => d.zorgkantoorregionaam)
          this.perc_prognose_2050 = response.data.map(d => ((d.perc_prognose_2050)*100))
          this.perc_prognose_2050_table = response.data.map(d => ((d.perc_prognose_2050)*100).toFixed(2))

          this.load.graphSix=true
          }
        )
    }
  },
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`api/zorgkantoor_totaal/zorgkantoor/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => a.proportie-b.proportie)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.netwerken = response.data.map(d => d.zorgkantoorregionaam)
          this.verschil = response.data.map(d => (d.proportie))
          this.verschil_table = response.data.map(d => (d.proportie*100).toFixed(1))
          this.netwerken_top5 = response.data.map(d => d.zorgkantoorregionaam).slice(-5)
          this.verschil_top5 = response.data.map(d => (d.proportie)).slice(-5)
          this.netwerken_bot5 = response.data.map(d => d.zorgkantoorregionaam).slice(0,5)
          this.verschil_bot5 = response.data.map(d => (d.proportie)).slice(0,5)
          this.load.graphTwo=true
          }
        )
      }

      else if(level === 'landelijk'){
        this.$http.get(`api/zorgkantoor_totaal/zorgkantoor/?landelijk=True`).then(
        (response) => {
          response.data.sort((a,b) => a.jaar-b.jaar)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.netwerken = response.data.map(d => d.zorgkantoorregionaam)
          this.zorgkantoor_totaal_jaar = response.data.map(d => d.jaar)
          this.verschil = response.data.map(d => (d.proportie*100))
          this.verschil_table = response.data.map(d => (d.proportie*100).toFixed(2))
          this.load.graphSix=true
          }
        )
      }

      else {
        this.$http.get(`api/dementienetwerk_totaal/dementienetwerk_leeftijd/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => a.proportie-b.proportie)
          this.netwerken = response.data.map(d => d.dementienetwerknaam)
          this.verschil = response.data.map(d => (d.proportie))
          this.verschil_table = response.data.map(d => (d.proportie*100).toFixed(1))
          this.netwerken_top5 = response.data.map(d => d.dementienetwerknaam).slice(-5)
          this.verschil_top5 = response.data.map(d => (d.proportie)).slice(-5)
          this.netwerken_bot5 = response.data.map(d => d.dementienetwerknaam).slice(0,5)
          this.verschil_bot5 = response.data.map(d => (d.proportie)).slice(0,5)
          this.load.graphTwo=true
          }
        )
      }
      this.$http.get(`/api/verdeelmodel/get_data/`).then(
        (response) => {
          response.data = response.data.filter(x => x.variabele !=="99.voldoet aan beweegrichtlijn")
          response.data = response.data.filter(x => x.variabele !=="99.ervaart eenzaamheid")
          response.data = response.data.filter(x => x.variabele !=="99.gehoorproblemen")
          response.data = response.data.filter(x => x.variabele !=="99.blaasontsteking")
          response.data = response.data.filter(x => x.variabele !=="3.gemon20")
          response.data = response.data.filter(x => x.variabele !=="Constant")
          this.variabele = response.data.map(d => d.variabele)
          this.probability = response.data.map(d => (d.prob_variable*100))
          var colorArray = [];
          for (var i = 0; i<this.probability.length; i++) {
            if (this.probability[i] < 0) {
              colorArray.push('green')
            }
            else {
              colorArray.push('Maroon')
            }
          }
          this.colorbar = colorArray
        }
      )

    },
    loadMapData (level) {
      this.$http.get(`/api/gemeente_totaal/gemeente_leeftijd/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.locations = response.data.map(d => d.gemeentecode)
          this.text = response.data.map(d => d.gemeentenaam)
          this.z = response.data.map(d => (d.proportie*100))
        }
      )
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/gemeente_totaal/gemeente_factorZK/?jaar=2020`).then(
          // ?jaar=${this.$store.state.selectedYear}
          (response) => {
            this.location_factors = response.data.map(d => d.gemeentecode)
            this.location_names = response.data.map(d => d.zorgkantoorregionaam)
            this.factors = response.data.map(d => (d.factor*100))
            this.factors_table = response.data.map(d => (d.factor*100).toFixed(1))
            this.load.graphOne = true
          }
        )
      }
      else {
        this.$http.get(`/api/gemeente_totaal/gemeente_factorDN/?jaar=2020`).then(
          // ?jaar=${this.$store.state.selectedYear}
          (response) => {
            this.location_factors = response.data.map(d => d.gemeentecode)
            this.location_names = response.data.map(d => d.dementienetwerknaam)
            this.factors = response.data.map(d => (d.factor*100))
            this.factors_table = response.data.map(d => (d.factor*100).toFixed(1))
            this.load.graphOne = true
          }
        )
      }
    }
  },
  mounted () {

    this.loadAllData(this.selectedLevel),
    this.loadAggregatedRegionData(this.selectedRegion),
    this.loadMapData(this.selectedLevel)
    // this.loadMapData2()
  }
}

</script>

<style>
.heading1 {
  text-align: left;
}
.paragraph {
  text-align: left;
}

.custom-link{
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
  color: var(--color-5);
}

.custom-link:visited {
  color: var(--color-7);
}

.custom-link:hover {
  border-bottom: 1px solid;
  color: var(--color-2);
  background: var(--color-1);
  border-radius: .5em;
}


.middle-title-section {
  width: 100%;
  position: relative;
}


.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

.intro-section-subtitle{
  font-weight: bold;
  font-size: 30px;
}

</style>
